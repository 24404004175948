.join-again-background {

    display: flex;
    width: 100%;
    margin-bottom: 10%;
    margin-top: 50px;
    height: 100%;
    background: transparent;
    align-items: center;
    justify-content: center;
}

.join-again-container {
    position: relative;
    width: 100%;
margin: 20px;
    max-width: 450px;

    background: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0px 4px 9px #171a1f, 0px 0px 2px #171a1f;
    text-align: center;
    padding: 20px;
}

.join-again-title {
    font-family: Epilogue;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: #171A1FFF;
}

.join-again-description {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #171A1FFF;
    margin-top: 20px;
}

.input-container,
.textarea-container {
    margin-top: 20px;
    text-align: left;
    max-width: 95%;
}

.input-field,
.textarea-field {
    width: 100%;
    padding: 10px;
    border: 1px solid #9095A0FF;
    border-radius: 2px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #171A1FFF;
    margin-bottom: 10px;
    resize: none;
}

.submit-button {
    position: relative;
    left: 0.5rem;
    top: 30%;
    width: 200px;
    height: 50px;
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    color: #0A4D20FF;
    background: #1DD75BFF;
    opacity: 1;
    border: none;
    border-radius: 33px;
    padding: 15px 30px;
    cursor: pointer;
}

.submit-button:hover {
    background: #1AC052FF;
    color: #0A4D20FF;
}

.submit-button:active {
    background: #17A948FF;
    color: #0A4D20FF;
}

.submit-button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .join-again-background {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .join-again-container {
        margin-top: 30px;
        min-width: 300px;
    }

    .join-again-title {
        font-size: 20px;
        line-height: 30px;
    }

    .join-again-description {
        margin-top: 15px;
        font-size: 12px;
        line-height: 20px;
    }

    .input-field,
    .textarea-field {
        margin-bottom: 8px;
    }

    .submit-button {
        top: 20%;
        width: 180px;
        height: 45px;
        font-size: 16px;
        padding: 12px 25px;
    }
}

@media (max-width: 480px) {
    .join-again-container {
        min-width: 300px;
    }
}