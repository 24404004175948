.sales-navbar {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    background: transparent;
    justify-items: center;
    justify-content: space-between;
    align-self: flex-end;
    padding: 20px;
    margin-left: 20px;
    padding-top: 30px;
    overflow-x: hidden;
}

.sales-nav-button {
    text-decoration: none;
    color:white;
}


/* Buttons */
.sales-navbar-buttons {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-right: 50px;
    flex-direction: row;
    font-size: 22px;
    line-height: 36px;
    color: #F3F4F6FF;
    cursor: pointer;
    align-items: center;
}

.sales-nav-button,
.sales-nav-button2 {
    margin-left: 20px;
}

.sales-nav-button:hover,
.sales-nav-button2:hover,
.join-button:hover {
    opacity: 1.2;
    transition: opacity 0.3s ease-in-out;
}
.sales-nav-button.active, .sales-nav-button2.active {
    font-weight: bold;
    border-bottom: 3px solid #FFF;
}

.join-button:hover {
    opacity: 1.2;
}


.sales-nav-button:last-child,
.sales-nav-button2:last-child {
    margin-left: auto; /* Push the last button to the right */
}

/* Button */
.join-button {
    text-decoration: none;
    margin-top: 40px;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Inter;
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    color: white;
    background: #E47CCD;
    opacity: 1;
    width: 237px;
    height: 57px;
    border-radius: 12px;
    cursor: pointer;
}

/* Hover */
.join-button:hover {

    background: rgba(228, 124, 205, 1);
    opacity: 0.8;
}


.activeSales {
    font-weight: bold;
    border-bottom: 3px solid #FFF;
}

.sales-navbar-buttons:hover {
    opacity: 1.2;
    transition: opacity 0.3s ease-in-out;
}

@media (max-width: 600px) {
    .sales-navbar {
        flex-direction: column; /* Stack the content vertically */
        height: auto; /* Let the height be determined by content */
        overflow-x: hidden;
    }

    .sales-title-text {
        font-size: 26px;
        line-height: 52px;
    }
}





@media (max-width: 1280px) {
    .sales-navbar-buttons {

        font-size: 14px;
        line-height: 36px;
        margin-left: 0;
        margin-right: 0;
    }
    .sales-nav-button,
    .sales-nav-button2 {
        margin-left: -15px;
    }

}
@media (max-width: 1980px) {
    .sales-navbar-buttons {
        font-size: 14px;

        line-height: 36px;
       white-space: nowrap;
    }
}