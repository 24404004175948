/* FrequentQuestions Container */
.faq-container {
    width: 100%;
    background: rgba(0, 0, 0, 0.6);
    padding: 80px;
    border-bottom: 1px solid #ccc;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
}

/* FrequentQuestions Item */
.faq-item {
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    width: calc(100% - 60px); /* Reduced width to 90% for padding */
    transition: background-color 0.3s ease;
    position: relative;
    padding: 15px;
    margin-left: 5px; /* Margin of 5% to center the item with 5% padding on both sides */
    margin-right: 5px;
}



/* Question */
.faq-question {
    padding: 10px;
    font-weight: bold;
    color: white;

}
.faq-desktop{
    margin-left: 50px;
width: 50%;
margin-bottom: 50px;

}
/* Answer (Initially Hidden) */
.faq-answer {
    padding: 10px;
    max-height: 0;
    overflow: hidden;
    display: block;
    color: white;
    transition: max-height 0.5s ease;
}

/* Active Answer */
.faq-item.active .faq-answer {
    max-height: 1000px;
    transition: max-height 0.5s ease;
}

/* Icon for Toggle */
.faq-toggle-icon {
    position: absolute;
    top: 15px;
    right: 10px;
    transform:rotate(0deg);
    fill: white;
    transition: transform 0.3s ease;
}

/* Icon Rotation for Active Item */
.faq-item.active .faq-toggle-icon {
    transform:rotate(180deg);
}

@media (max-width: 768px) {
    .faq-title {
        font-size: 32px;
        line-height: 45px;
    }

    .faq-question {
        font-size: 16px;
        padding: 8px;
    }

    .faq-answer {
        padding: 8px;
    }

    .faq-item {
        padding: 10px;
        margin-left: 2%; /* Adjusted for smaller screens */
        margin-right: 2%;
    }
}

/* Responsive adjustments for smartphones */
@media (max-width: 600px) {
    .faq-desktop{
        margin-left: 0;
        width: 80%;
    }



    .faq-title {
        font-size: 24px;
        line-height: 34px;
    }

    .faq-question {
        font-size: 14px;
        padding: 6px;
    }

    .faq-answer {
        padding: 6px;
    }

    .faq-item {
        padding: 8px;
        margin-left: 1%; /* Adjusted for smaller screens */
        margin-right: 1%;
    }
}
