.sales-hero-container {
    align-items: center;
    background: transparent;
overflow: hidden;
    display: flex;
    flex-direction: row;
    padding-left: 20px;
}
.sales-left-container{
    margin-left: 5vh;
    max-width: 50vh;

}
.sales-title {
    font-family: Inter;
    font-size: 54px;
    font-weight: 600;
    line-height: 65px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #FFFFFFFF;
}

.sales-title-2 {
    font-family: Inter;
    font-size: 54px;
    font-style: italic;
    font-weight: 800;
    letter-spacing: -0.01em;
    text-align: left;
    background: linear-gradient(94.33deg, #FF9FEA 30.43%, #FFFFFF 86.78%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: inline-block;
}
/* SalesHero.css */
.modal {
    z-index: 9999;
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: #131313;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 30%;
    max-width: 400px;
    color: white;
}

.modal h2 {
    text-align: center;
    margin-bottom: 20px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10000;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-group {
    margin-bottom: 20px;
    width: 100%;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
}

.form-group input,
.form-group textarea {
    width: 93%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.submit-button {
    background: #ffffff;
    color: #131313;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submit-button:hover {
    background: #ddd;
}



.sales-subtitle {
    position: relative;
    font-family: Inter;
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;

    letter-spacing: -0.01em;
    text-align: left;
    color: #DCDCDC;

}


.container-bottom-left,
.container-bottom-right {
    position: relative;
    width: auto;
    height: 680px;
    z-index: -1;
    display: flex;
    justify-content: center; /* Centering the image horizontally */
    align-items: flex-end; /* Aligning the image to the bottom */
}
.container-bottom-left{
    margin-left: 10vh;

}
/* Styles for images within the containers */
.container-bottom-left img,
.container-bottom-right img {
    width: auto; /* Maintain aspect ratio */
    height: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Ensuring image does not overflow the container */
    max-height: 100%; /* Ensuring image does not overflow the container */
    pointer-events: none; /* Prevents click and other pointer events */
    user-select: none; /* Prevents text selection cursor and selection */
    -webkit-user-drag: none; /* Prevents dragging on webkit browsers */
    -moz-user-select: none; /* Firefox-specific select prevention */
    -ms-user-select: none; /* Internet Explorer/Edge select prevention */
    transform-origin: bottom; /* Ensures scaling is from the bottom */
    transform: scale(0.9); /* Apply scale */
}

@media screen and (max-width: 768px) {
    .sales-hero-container {
        flex-direction: column; /* Stack elements vertically */
        padding-left: 0;
    }

    .container-bottom-left{
        display: none;

    }
    .container-bottom-left,
    .container-bottom-right {
        margin-left: 0;
        max-width: 100%; /* Full width for smaller screens */
        width: 100%; /* Full width for smaller screens */
        height: auto; /* Auto height for smaller screens */
    }

    .container-bottom-left img,
    .container-bottom-right img {
        transform: scale(0.7); /* Adjust scale for smaller screens */
    }
}
@media screen and (min-width: 1980px) {
    .container-bottom-left{
        margin-left: 20vw;
    }
    .container-bottom-right {
        margin-left: 5vw;
    }
}



