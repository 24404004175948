
.features-container {
    display: flex;
    justify-content: center;
    gap: 10px;

}

.features-container.second-row {
    justify-content: space-around; /* Adjust the spacing for the second row */
    padding: 30px;
}

.features-section{
    padding: 80px;
    background: linear-gradient(325.24deg, rgba(82, 0, 255, 0.23) -61.78%, rgba(19, 19, 19, 0.8) 79.82%),
    linear-gradient(149.02deg, rgba(82, 0, 255, 0.53) -50.53%, rgba(19, 19, 19, 0) 70.77%);
}
.feature-icon-text{
    display: flex;
    align-items: center;
    flex-direction: row;
    font-family: Inter;
    font-size: 22px;
    gap: 15px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #FFFFFF;
}
.feature-title{
    position: relative;
    margin-bottom: 20px;
    padding: 40px;
    margin-left: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

}


/* Styling for individual feature items */
.feature-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;

}

/* Styling for feature icons */
.feature-icon {
    width: 44px; /* Adjust the icon size as needed */
    height: 44px;
    stroke: white;
}

.feature-description{
    color: #D6D6D6;
    font-family: Inter;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.01em;
    margin: auto;
    white-space: break-spaces;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}
@media screen and (max-width: 680px) {
    .features-container {
        flex-direction: column; /* Stack the items vertically */
        align-items: center; /* Center the items */
        padding: 20px; /* Reduce padding */

    }

    .features-container.second-row {
        justify-content: center; /* Center the second row items */
    }

    .feature-item {
        width: 100%; /* Full width for each feature item */
        margin-bottom: 20px; /* Add some space between items */
    }

    .feature-icon-text {
        flex-direction: column; /* Stack icon and text vertically */
        align-items: center; /* Center align */
        text-align: center; /* Center the text */
    }

    .feature-icon {
        margin-bottom: 10px; /* Space between icon and text */
    }

    .feature-description {
        font-size: 14px; /* Adjust font size */
        text-align: center; /* Center the title text */
    }

    .feature-title {
        margin-left: 0; /* Add some space between the title and items */
        text-align: center; /* Center the title text */
    }

    .feature-name {
        font-size: 18px; /* Adjust the feature name font size */
    }

    /* Additional adjustments for titles and subtitles */
    .upper-text, .lower-text{
        font-size: 26px; /* Reduce font size for smaller screens */
    }
    .carousel-subtitle-2{
        font-size: 16px;


    }
}

@media screen and (min-width: 1280px) {
    .features-container {
        padding: 50px; /* Increased padding for larger screens */
    }

    .features-container.second-row {
        /* Adjustments for the second row on larger screens */
        padding: 50px;
    }

    .feature-icon-text {
        font-size: 24px; /* Increased font size */
        line-height: 32px;
        gap: 20px;
    }

    .feature-title {
        margin-bottom: 30px;
        padding: 50px;
        margin-left: 150px;
    }

    .feature-item {
        padding: 30px; /* Increased padding for each feature item */
    }

    .feature-icon {
        width: 60px; /* Larger icon size */
        height: 60px;
    }

    .feature-description {
        font-size: 18px; /* Slightly larger font size for descriptions */
        line-height: 28px;
    }
}


