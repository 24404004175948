/* General Footer Styles */
.footer {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 20px 40px;
    background-color: #0F0F0F;
    color: #FFF;
    font-family: 'Arial', sans-serif;
    overflow-x: hidden;
    justify-content: center;
    gap: 20px;
}
.footer-information{
    position: relative;
    margin-bottom: 20px;
    padding: 40px;
    margin-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-subtitle {
    font-size: 16px;
    font-weight: bold;
    white: white;
    margin-bottom: 10px;
}

.footer-logo {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.footer-logo span {

    font-size: 24px;
    font-weight: bold;
}

.footer-logo svg {
    width: 120px;
    height: 120px;
}

.DiscordIconFooter {
    cursor: pointer; /* Change cursor to pointer to indicate clickability */
    display: flex; /* To align the SVG icon properly */
    align-items: center; /* Align items vertically */
    justify-content: center; /* Center content horizontally */
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
}

.DiscordIconFooter svg {
    width: 100%; /* Scale SVG to fit its container */
    height: auto;
    fill: #7289DA; /* Discord's brand color, adjust if needed */
}

