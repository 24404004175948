/* SalesHero.css */
.success-screen-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #e6ffed; /* light green background */
    border: 1px solid #b3e6cc; /* greenish border */
    border-radius: 5px;
}

.success-screen-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #34d399; /* greenish text */
}

.success-screen-message {
    font-size: 16px;
    color: #065f46; /* dark greenish text */
}
.discord-section {
    display: flex;
    align-items: center;
    margin-top: 20px; /* Space it a bit from the previous message */
}

.discord-icon {
    width: 40px;  /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    margin-right: 10px; /* Space between the icon and the text */
}

.discord-message {
    font-size: 16px;
    color: #7289DA; /* Discord's primary color, adjust if necessary */
}
