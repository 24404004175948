.overalls {
  overflow-x: hidden;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.background-sales-page{
  overflow-x: hidden;
width: 100%;
height: 100%;

}
.first-gradient {
  width: 100%;
  overflow-x: hidden;
  background: linear-gradient(180deg, #0F0F16 0%, rgba(46, 47, 72, 0) 100%),
  linear-gradient(275.36deg, rgba(142, 24, 235, 0.106) -5%, rgba(0, 0, 0, 0) 59.08%),
  linear-gradient(55.75deg, rgba(142, 24, 235, 0.106) 10.75%, rgba(0, 0, 0, 0) 60.65%);
}


.features-gradient {
  /* Set the background image with reduced opacity */
  /*background-image: url('./sales/icons/girl1.jpeg'); !* Adjust the path as needed *!*/
  background-size: 30% auto; /* 30% of the container's width and auto height to maintain aspect ratio */
  background-position: left bottom; /* Align to the left and vertically centered */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  position: relative;
  z-index: 1;
  max-height: 100%; /* Limit the height */
  overflow: hidden; /* Hide any overflow */
}

.features-gradient::before {

  /* Create a pseudo-element for the opacity effect */
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5); /* White background with 50% opacity */
  z-index: -1;
}

.frequent-questions-bg {

  /*background-image: url('./sales/icons/girl2.png'); !* Adjust the path as needed *!*/
  background-size: 30% auto; /* 30% of the container's width and auto height to maintain aspect ratio */
  background-position: right bottom; /* Align to the bottom right */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  position: relative;
  z-index: 1;
  max-height: 100vh; /* Limit the height */
  overflow: hidden; /* Hide any overflow */
}

.frequent-questions-bg::before {

  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; /* Starts from 70% of the left side to cover the right 30% */
  background: rgba(255, 255, 255, 0.1); /* White background with 50% opacity */
  z-index: -1;

}
